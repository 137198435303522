/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-01-19 11:04:41
 * @LastEditors: hutian
 * @LastEditTime: 2021-01-23 18:58:11
 */
/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listTaskFollowUp = params => axios({
    url: '/api/customer/customer/taskFollowUp/list',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const addTaskFollowUp = params => axios({
    url: '/api/customer/customer/taskFollowUp/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editTaskFollowUp = params => axios({
    url: '/api/task/system/taskFollowUp/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delTaskFollowUp = params => axios({
    url:'/api/task/system/taskFollowUp/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdTaskFollowUp = params => axios({
    url: '/api/task/system/taskFollowUp/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})


export const Alarm = params => axios({
    url: '/api/order/system/orderAbnormalAlarm/list',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const Record = params => axios({
    url: '/api/order/system/orderScanningDetail/list',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})
